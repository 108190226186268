import { Link } from "react-router-dom";
import { ROUTE_RANKINGS } from "..";
import Screenshot from "../../assets/Screenshot.png";

const stats = [
  { id: 1, name: "Profit", value: "$75K +" },
  { id: 2, name: "Playoff Profit", value: "$50K +" },
  { id: 3, name: "First Place Finishes", value: "🏆 4" },
  { id: 4, name: "Playoff First Place Finishes", value: "🏆 3" },
];

function Home() {
  return (
    <>
      <div className="bg-white py-4 sm:py-8">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Your top source for best ball rankings
            </h1>
            <p className="mt-6 text-base leading-6 text-gray-500">
              Welcome to my best ball rankings. These are the same, up-do-date,
              rankings that I use when I enter contests and that have personally
              won over <strong className="text-sky-500">$75K</strong> for me.
              Excited to have you tail along!
            </p>
          </div>
          <div className="text-center mt-10">
            <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-gray-400/10 p-8">
                  <dt className="text-sm font-semibold leading-6 text-gray-600">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
            <h4 className="uppercase text-xs text-gray-500 font-bold my-2">
              Last Two Years
            </h4>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-8 lg:px-10">
            <img
              src={Screenshot}
              alt="Best Ball Rankings Screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to={ROUTE_RANKINGS}
              className="rounded-md bg-sky-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              Get Access
            </Link>
            <Link
              to={ROUTE_RANKINGS}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Preview <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
