import React from "react";
import { ROUTE_RANKINGS } from "..";
import { Link } from "react-router-dom";
import { rankingsList } from "../../rankings";

function Table() {
  return (
    <div className="overflow-hidden rounded-lg shadow-sm border border-gray-200">
      <div className="min-w-full divide-y divide-gray-300">
        <div className="bg-gray-50">
          <div className="grid grid-cols-10 py-2.5 px-3.5">
            <div className="col-span-10 sm:col-span-2 text-left text-xs font-semibold text-gray-500 uppercase">
              Contest Name/Type
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 bg-white">
          {rankingsList.map((contest, index) => {
            return (
              <Link
                to={`${ROUTE_RANKINGS}/${contest.id}`}
                className="grid grid-cols-10 text-left w-full px-3.5 py-3 items-center"
                key={index}
              >
                <div className="col-span-12 sm:col-span-5 text-left text-base font-semibold text-gray-900">
                  {contest.name}
                </div>
                <div className="col-span-12 sm:col-span-5 text-left sm:text-right pt-2 sm:pt-0">
                  <button className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    View
                  </button>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Table;
