import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./Routes";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./contexts";
import mixpanel from "./services/mixpanel";

const router = createBrowserRouter(routes);

function App() {
  const [mixpanelInitUnauth, setMixpanelInitUnauth] = useState(false);
  const [mixpanelInitAuth, setMixpanelInitAuth] = useState(false);
  const [mixpanelSubmittingUnauth, setMixpanelSubmittingUnauth] =
    useState(false);
  const [mixpanelSubmittingAuth, setMixpanelSubmittingAuth] = useState(false);
  const { user, authenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!mixpanelInitAuth && !mixpanelSubmittingAuth && authenticated) {
      setMixpanelSubmittingAuth(true);
      mixpanel.identify(user.uid);
      setMixpanelInitAuth(true);
      setMixpanelSubmittingAuth(false);
    }
    if (!mixpanelInitUnauth && !mixpanelSubmittingUnauth) {
      setMixpanelSubmittingUnauth(true);
      mixpanel.track("Connect Site View", {
        connectedAccount: "james",
      });
      setMixpanelInitUnauth(true);
      setMixpanelSubmittingUnauth(false);
    }
  }, [
    user,
    authenticated,
    mixpanelInitAuth,
    mixpanelInitUnauth,
    mixpanelSubmittingAuth,
    mixpanelSubmittingUnauth,
  ]);

  return <RouterProvider router={router} />;
}

export default App;
