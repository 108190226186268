import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  confirmPasswordReset,
  verifyPasswordResetCode,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  updateDoc,
  doc,
  collection,
  where,
  getDocs,
  getDoc,
  query,
  orderBy,
} from "firebase/firestore";
import mixpanel from "./mixpanel";

//firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const functions = getFunctions(app);
if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
const auth = getAuth(app);

//register function
const register = async ({
  email,
  password,
  firstName,
  lastName,
  mixpanel_id,
  connectAccount,
}) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      firstName,
      lastName,
      createTime: new Date(),
      mixpanel_id,
      connectedAccounts: {
        [connectAccount]: {
          connected: true,
        },
      },
    });
    return { uid: user.uid };
  } catch (error) {
    let errorMessage = generateResponseMessage(error);
    return { error: errorMessage, code: error.code };
  }
};

const generateResponseMessage = (error) => {
  let message;

  switch (error.code) {
    case "auth/too-many-requests":
      message = "Too many login attempts. Please wait a moment and try again.";
      break;
    case "auth/user-not-found":
      message =
        "User not found. Please check your credentials or sign up to create a new account.";
      break;
    case "auth/wrong-password":
      message =
        "Incorrect password. Please try again. If you've forgotten your password, you can reset it.";
      break;
    case "auth/email-already-exists": // Consider removing this case if it's not used in Firebase Auth
      message =
        "This email address is already in use. Please use a different email or try logging in.";
      break;
    case "auth/email-already-in-use":
      message =
        "This email is already registered. Please sign in or use a different email to create a new account.";
      break;
    case "auth/invalid-email":
      message =
        "The email address is badly formatted. Please check and try again.";
      break;
    case "auth/user-disabled":
      message =
        "This account has been disabled. Please contact support for assistance.";
      break;
    case "auth/operation-not-allowed":
      message =
        "This operation is not allowed. Please contact support if you need assistance.";
      break;
    default:
      message = error.message;
      break;
  }
  return message;
};

// Sign in funciton
const signIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    let errorMessage = generateResponseMessage(error);
    return { error: errorMessage };
  }
};

// Sign out function
const logOut = async () => {
  try {
    mixpanel.reset();
    await signOut(auth);
    return true;
  } catch (error) {
    return false;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    let errorMessage = generateResponseMessage(error);
    return { error: errorMessage };
  }
};

const verifyResetCode = async (code) => {
  await verifyPasswordResetCode(auth, code);
  return true;
};

//Password reset function
const resetPassword = async ({ password, code }) => {
  await confirmPasswordReset(auth, code, password);
  return true;
};

const findDocument = async (...route) => {
  try {
    const docRef = doc(db, ...route);

    // Fetch documents
    const querySnapshot = await getDoc(docRef);
    return querySnapshot.data();
  } catch (e) {
    return {};
  }
};

export const createCheckoutLink = httpsCallable(
  functions,
  "stripe-createWLCheckoutLink"
);

export {
  findDocument,
  register,
  signIn,
  logOut,
  onAuthStateChanged,
  auth,
  db,
  analytics,
  functions,
  httpsCallable,
  connectFunctionsEmulator,
  updateDoc,
  doc,
  sendPasswordReset,
  verifyResetCode,
  resetPassword,
  collection,
  where,
  query,
  getDocs,
  getDoc,
  orderBy,
};
