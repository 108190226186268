import React, { useState, useEffect, useContext } from "react";
import { Formik, Field, Form } from "formik";
import { Input } from "../../Components/Forms";
import Spinner from "../../Components/Spinner";
import { db, signIn, updateDoc, doc } from "../../services/firebase";
import mixpanel from "../../services/mixpanel";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import * as Yup from "yup";
import { AuthContext } from "../../contexts";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
import { getCheckoutUrl } from "../../utils";
import { ROUTE_HOME } from "..";

const Login = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [authError, setAuthError] = useState(null);
  const { authenticated, userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToCheckout = async () => {
      if (!userInfo?.connectedAccounts?.james?.connected) {
        console.log("adding connected account");
        mixpanel.track("Connected", {
          connectAccount: "james",
        });
        await await updateDoc(doc(db, "users", userInfo?.uid), {
          "connectedAccounts.james.connected": true,
        });
      }
      console.log("Redirecting");
      const url = await getCheckoutUrl();
      console.log("URL: ", url);
      if (url) {
        window.location.href = url;
      }
    };
    if (
      authenticated &&
      userInfo &&
      userInfo?.connectedAccounts?.james?.connected &&
      !loadingLink
    ) {
      navigate(ROUTE_HOME);
    } else if (
      userInfo &&
      authenticated &&
      !userInfo?.connectedAccounts?.james?.connected
    ) {
      setLoadingLink(true);
      redirectToCheckout();
    }
  }, [userInfo, authenticated, loadingLink, navigate]);

  const onSubmit = async ({ email, password }) => {
    setSubmitting(true);

    const res = await signIn({
      email,
      password,
    });

    if (res.error) {
      setAuthError(res.error);
      setSubmitting(false);
    } else {
      return null;
    }
  };

  const renderAuthError = () => {
    if (authError) {
      return (
        <div className="rounded-md bg-rose-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-rose-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-rose-800">{authError}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setAuthError(null)}
                  className="inline-flex bg-rose-50 rounded-md p-1.5 text-rose-500 hover:bg-rose-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rose-50 focus:ring-rose-600"
                >
                  <span className="sr-only">Dismiss</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please input a valid email")
      .required("required"),
    password: Yup.string().required("Required"),
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* Logo Here */}
          <img className="mx-auto h-20 w-auto" src={Logo} alt="SportsMind" />
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {renderAuthError()}
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form className="" action="#" method="POST">
                <div className="mt-6">
                  <Field
                    name="email"
                    label="Email"
                    type="email"
                    component={Input}
                  />
                </div>
                <div className="mt-6">
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    component={Input}
                  />
                </div>
                <div className="flex justify-end mt-1 mb-5">
                  <Link
                    to="/forgot-password"
                    className="font-semibold leading-6 text-sky-500 text-sm hover:text-sky-600"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full text-center flex justify-center py-2 px-4 rounded-md shadow-sm border border-transparent text-sm font-medium text-white bg-sky-500 hover:bg-skya-600"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <Spinner height={20} color="#FFFFFF" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>
            </Formik>
            <div className="my-2">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">Or</span>
                </div>
              </div>
            </div>
            <div className="relative flex justify-between text-sm">
              <Link
                to="/register"
                className="w-full text-center flex justify-center py-2 px-4 rounded-md shadow-sm border border-gray-900 text-sm font-medium text-gray-900 bg-transparent hover:text-sky-500 hover:border-sky-500"
              >
                Create Account
              </Link>
              {/* <a
              href="https://savemywordle.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="text-primary-500 hover:text-primary-700 font-medium text-sm mt-6"
            >
              Home
            </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
