import { createCheckoutLink } from "./services/firebase";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const capitalizeFirst = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validateEmail = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    // eslint-disable-line
    return true;
  } else return false;
};

export function getInitials(firstName, lastName) {
  let firstInitial = firstName ? firstName[0].toUpperCase() : "";
  let lastInitial = lastName ? lastName[0].toUpperCase() : "";

  return firstInitial + lastInitial;
}

export async function getCheckoutUrl() {
  const { data } = await createCheckoutLink({
    priceId: process.env.REACT_APP_PRICE_ID,
    priceName: "James Brimacombe - Best Ball Rankings",
    cancelUrl: process.env.REACT_APP_SUCCESS_URL,
    successUrl: process.env.REACT_APP_CANCEL_URL,
    connectedAccount: "james",
  });

  console.log("DATA: ", data);

  return data?.url;
}
