import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import { AuthContext } from "../../../contexts";
import { useNavigate, useParams } from "react-router";
import { ROUTE_LOGIN } from "../../";
import { getCheckoutUrl } from "../../../utils";
import Spinner from "../../../Components/Spinner";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { rankingsList } from "../../../rankings";

function DraftersTable() {
  const [players, setPlayers] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { authenticated, isPurchased } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const rankingId = params.rankingId;

  useEffect(() => {
    const fetchCSV = async (path) => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/assets/${path}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("text/csv")) {
          throw new TypeError("The fetched file is not in CSV format!");
        }
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setPlayers(results.data);
          },
        });
      } catch (error) {
        console.error("Failed to fetch the CSV: ", error);
      }
    };

    const rankingData = rankingsList.find(
      (ranking) => ranking.id === rankingId
    );
    if (rankingData) {
      fetchCSV(rankingData.asset);
    }
  }, [rankingId]);

  const handleCheckout = async () => {
    setSubmitting(true);
    const url = await getCheckoutUrl();

    if (url) {
      window.location.href = url;
    }
  };

  const displayPlayers =
    authenticated && isPurchased ? players : players.slice(0, 5) || [];

  return (
    <div className="overflow-hidden rounded-lg shadow-sm border border-gray-200">
      <div className="min-w-full divide-y divide-gray-300">
        <div className="bg-gray-50">
          <div className="grid grid-cols-10 py-2.5 px-3.5">
            <div className="hidden sm:block sm:col-span-1 text-left text-sm font-semibold text-gray-900">
              My Rank
            </div>
            <div className="col-span-10 sm:col-span-3 text-left text-sm font-semibold text-gray-900">
              Player Name
            </div>
            <div className="hidden sm:block sm:col-span-2 text-left text-sm font-semibold text-gray-900">
              ADP
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-sm font-semibold text-gray-900">
              Diff
            </div>
            <div className="hidden sm:block sm:col-span-1  text-left text-sm font-semibold text-gray-900">
              Avg
            </div>
            <div className="hidden sm:block sm:col-span-1 text-left text-sm font-semibold text-gray-900"></div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 bg-white">
          {displayPlayers.map((player, index) => {
            const diff = Number(player.ADP) - (index + 1);
            return (
              <Disclosure key={index} defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="grid grid-cols-10 text-left w-full px-3.5 py-3 items-center">
                      <div className="col-span-1 sm:col-span-1 text-left text-sm font-semibold text-gray-900">
                        <div className="bg-sky-100 text-sky-900 text-xs font-semibold h-7 w-7 flex items-center justify-center rounded-md">
                          {index + 1}
                        </div>
                      </div>
                      <div className="col-span-8 sm:col-span-3 text-left text-sm font-semibold text-gray-900 pl-2 sm:pl-0">
                        {player.name}
                      </div>
                      <div className="sm:hidden col-span-1 text-gray-500">
                        {open ? (
                          <MinusIcon className="h-4 w-4" />
                        ) : (
                          <PlusIcon className="h-4 w-4" />
                        )}
                      </div>
                      <div className="sm:hidden col-span-10 pt-3"></div>
                      <div className="col-span-2 sm:col-span-2 text-left text-sm text-gray-500">
                        <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                          ADP
                        </span>
                        {player.ADP}
                      </div>
                      <div className="col-span-8 sm:col-span-1 text-left text-sm text-gray-500">
                        <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                          ADP DIFF
                        </span>
                        <span
                          className={`whitespace-nowrap  text-sm ${
                            diff
                              ? diff > 0
                                ? "text-green-600"
                                : "text-red-600"
                              : "text-gray-500"
                          }`}
                        >
                          {diff
                            ? diff > 0
                              ? `+${diff.toFixed(0)}`
                              : diff.toFixed(0)
                            : "-"}
                        </span>
                      </div>
                      <div className="col-span-2 sm:col-span-1 text-left text-sm text-gray-500">
                        <span className="sm:hidden text-xs text-gray-500 font-semibold mr-1">
                          AVG
                        </span>
                        {player.AVG}
                      </div>
                      <div className="hidden sm:block sm:col-span-1 text-left text-gray-500">
                        {open ? (
                          <MinusIcon className="h-4 w-4" />
                        ) : (
                          <PlusIcon className="h-4 w-4" />
                        )}
                      </div>
                    </Disclosure.Button>
                    {player.notes && (
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="bg-gray-100">
                          <div className="py-2 sm:py-3.5 px-3.5 text-sm text-gray-700">
                            {player.notes}
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    )}
                  </>
                )}
              </Disclosure>
            );
          })}
          {!authenticated || !isPurchased ? (
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 text-center sm:px-16">
              <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900">
                Get the full rankings and start winning money!
              </h2>
              <p className="mx-auto mt-4 max-w-xl text-base leading-8 text-gray-500">
                Get the rankings that have won over $50K for only $9.99.
                Download and upload directly into Underdog for easy use.
              </p>
              <div className="mt-4 flex items-center justify-center gap-x-6">
                <button
                  type="button"
                  onClick={() => {
                    authenticated ? handleCheckout() : navigate(ROUTE_LOGIN);
                  }}
                  className="inline-flex items-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
                >
                  {submitting ? <Spinner color="#FFFFFF" /> : "Get Rankings →"}
                </button>
              </div>
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                aria-hidden="true"
              >
                <circle
                  cx="512"
                  cy="512"
                  r="512"
                  fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                  fillOpacity="0.7"
                />
                <defs>
                  <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                    <stop stopColor="#7775D6" />
                    <stop offset="1" stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default DraftersTable;
