import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

function Layout({ navRoutes }) {
  return (
    <div>
      <Navbar navRoutes={navRoutes} />
      <div className="mx-auto max-w-7xl p-6 lg:px-8">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
