import Layout from "../Layout";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Home from "./Home";
import PlayoffOdds from "./PlayoffOdds";
import RankingView from "./RankingView";
import Rankings from "./Rankings";
import Strategy from "./Strategy";

export const ROUTE_HOME = "/";
export const ROUTE_RANKINGS = "/rankings";
export const ROUTE_RANKING_VIEW = "/rankings/:rankingId";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_PLAYOFF_ODDS = "/playoff-odds";
export const ROUTE_STRATEGY = "/strategy";

const navRoutes = [
  {
    name: "Rankings",
    href: ROUTE_RANKINGS,
  },
  // {
  //   name: "Playoff Odds",
  //   href: ROUTE_PLAYOFF_ODDS,
  // },
  // {
  //   name: "Strategy",
  //   href: ROUTE_STRATEGY,
  // },
];

export const routes = [
  {
    path: "/",
    element: <Layout navRoutes={navRoutes} />,
    children: [
      {
        path: ROUTE_HOME,
        element: <Home />,
      },
      {
        path: ROUTE_RANKINGS,
        element: <Rankings />,
      },
      {
        path: ROUTE_RANKING_VIEW,
        element: <RankingView />,
      },
      {
        path: ROUTE_PLAYOFF_ODDS,
        element: <PlayoffOdds />,
      },
      {
        path: ROUTE_STRATEGY,
        element: <Strategy />,
      },
    ],
  },
  {
    path: ROUTE_LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_REGISTER,
    element: <Register />,
  },
];
