import React from "react";

function Strategy() {
  return (
    <div className="bg-white px-6 py-8 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-sky-600">
          Underdog Fantasy Best Ball
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          How To Win The Gauntlet
        </h1>
        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
          What is The Gauntlet?
        </h2>
        <ul className="list-disc pl-4 mt-2 leading-6 text-gray-600 text-base">
          <li>$1.5 Million Prize Pool with $150,000 to 1st place</li>
          <li>Max Entrants = 67,200</li>
          <li>Currently 14% Full (10,000)</li>
          <li>Game Type = NFL Playoff Best Ball</li>
          <li>Max Entries = 150</li>
          <li>Entry Fee = $25</li>
          <li>Draft Size = 6-Man Drafts</li>
          <li>
            Rounds = 10 Rounds (10 Player Rosters = 1 QB, 1 RB, 2 WR/TE, 1 Flex,
            5 Bench)
          </li>
          <li>Must Start 5 Players = 1 QB, 1 RB, 2 WR/TE, 1 Flex (RB/WR/TE)</li>
          <li>
            Scoring = 0.5 Rec, 0.1 Rec/Rushing Yd, 6.0 Rec/Rush TD, 4.0 Passing
            TD, 0.04 Pass Yd, 2.0 2PT conv, -1.0 INT, -2.0 Fumble
          </li>
          <li>Pick Clock = Fast Draft – 30 Second Clock</li>
          <li>Rake = 10.7%</li>
        </ul>
        <div className="mt-6 max-w-2xl space-y-6">
          <p>
            The basic layout for the Gauntlet is listed above. At first glance
            playing in a Best Ball league might seem confusing and taking it a
            step further with a Playoff Best Ball contest can take it to another
            level. Once you learn the Best Ball basics you are on your way to
            drafting a team in under 15 minutes. After you draft one team, if
            you are like me or the thousands of others on Underdog you are going
            to catch the itch and want to draft another. Hopefully this article
            can be somewhat of a guide on how to draft the best possible team to
            help advance out of Round 1 and create a roster build for the next
            rounds and ultimately end up in the Super Bowl/Finals round.
          </p>
          <p>
            The wrinkle on these Underdog Playoff drafts is that if you are
            taking shots on San Francisco and Baltimore players who have a bye
            week in Round 1, they will score zero points for you. So with only
            10 roster spots and having to start 5 players it makes it harder to
            justify drafting a bunch of those players. Right now San Francisco
            is the favorite to win the Super Bowl but with no game in the first
            round of this contest makes you really have to think about what kind
            of strategy you want to use.
          </p>

          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            The Gauntlet Tournament Structure
          </h2>
          <ul className="list-disc pl-4 mt-2 leading-6 text-gray-600 text-base">
            <li>
              Round 1 = 6 Person Groups (Top 2 Teams (33.3%) advances to Round
              2) 67,200 Total entries
            </li>
            <li>
              Round 2 = 14 Person Groups (Top 2 Teams (14.3%) advance to Round
              3) 7,520 entries remaining = Guaranteed $50
            </li>
            <li>
              Round 3 = 15 Person Groups (Top 1 Team (6.7%) advances to Round 4
              FINALS) 1,410 entries remaining = Guaranteed $100
            </li>
            <li>
              Round 4 = 94 Person Group (Prizes range from $200,000 to 1st –
              $500 for 94th) = Guaranteed $500
            </li>
          </ul>
          <p className="mt-6">
            Looking at these numbers above I feel like you can approach this
            tournament two different ways. You can play it safe much like a
            “Cash” game in DFS and try to get out of Round 1 and double up your
            money, or you can have more of a “GPP” approach and take on some
            more risk in Round 1 with the Conference Finals (Round 3) in mind.
            Sure maybe I should have said Super Bowl/Finals or bust but the fact
            is if you can land a team in the Conference Finals Weekend and have
            five or more players still live, you are setting yourself up for
            success. The goal for me is to crack that 1,410/45,120 number which
            equals 3.125% chance of the team you draft to make it to Round 3 but
            to not only make it to that point but to also have five or more
            players still available to score points for you.
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            9 Ways to Have the Perfect Draft Team in the Gauntlet
          </h2>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            1. Have an end goal and a draft plan
          </h3>
          <p className="mt-2">
            I personally have drafted 300 playoff Mitten drafts along with 90
            Wild Card drafts. It was not until the Mitten 2 that I really felt
            like I had a grasp on how to draft correctly. Now that the playoff
            picture is clearer, we can attack the Gauntlet in a way that gives
            us the most chances to succeed and advance to Round 3. My end goal
            is to crack into Round 3 and the 3.125% with a team that has 5-7
            players that are still playing in the Conference Finals to help give
            me the best shot at advancing to the Finals.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            2. Be Bold and do not draft scared
          </h3>
          <p className="mt-2">
            The Gauntlet is going to be a battle and it starts with the $25
            entry fee. Maybe to some the $25 entry fee is modest but to me it
            makes me think twice when I enter the tournament. You can obviously
            play it safe and just try to advance out of Round 1 and double up
            your money but where is the fun in that? Just drafting the highest
            projected players for Round 1 is not the point of this contest. It
            is called the Gauntlet for a reason and ending a draft without a
            potential combo of 5-7 players out of your 10 for the Conference
            Finals weekend just feels wrong.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            3. Look at the playoff seedings and odds and tell yourself a story
          </h3>
          <p className="mt-2">
            You can easily run simulations of how the Conference Finals will
            shake out before the playoffs even begin. I have done this several
            times already and it is as easy as just jotting it down on paper. In
            the AFC I prefer teams like Buffalo, Cincinnati, and Kansas City and
            in the NFC teams like Dallas, Tampa Bay and the Rams. Those are the
            six teams I am finding myself trying to pair together while drafting
            my teams, trying to give myself the best shot not only in Round 1,
            but also in Round 2, and potentially setting myself up for five or
            more players for Round 3.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            4. Bet on a team and the entire offense vs individual players
          </h3>
          <p className="mt-2">
            You do not want to just take players from all the teams you want to
            zero in on a few teams in each draft that you are high on and try to
            build a roster with two or three different offenses. Think of it as
            stacking in season long where you take a QB/WR but for Playoff Best
            Ball you want to take it even further and go with QB/RB/WR as a base
            and even add another running back or receiver or two.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            5. You only have 10 roster spots so use them wisely
          </h3>
          <p className="mt-2">
            You have 10 shots to construct your Playoff Best Ball team. You want
            to be wise in how you draft in each round and each player you
            select. There is no exact science to what type of build you should
            do in this contest but a simple 1 QB/3 RB/6 WRTE, blueprint might
            not be a bad starting point. You obviously modify your builds as the
            drafts happen as so much can change round to round in the draft
            room.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            6. Do you draft one quarterback or two quarterbacks?
          </h3>
          <p className="mt-2">
            This question seems to be one that comes up quite a bit when talking
            strategy in this format. You are so limited with only having 10
            roster spots and having to start five players each round. If you go
            with two quarterbacks, you can only ever start one of them and now
            you are taking away one of your player spots that could be used at
            RB/WR/TE. You would take two quarterbacks every time you were to
            draft Aaron Rodgers or Ryan Tannehill though as both will score 0
            points for you in Round 1 and you would be basically throwing away
            your entry fee.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            7. How many running backs should you draft?
          </h3>
          <p className="mt-2">
            Much like the quarterback position, with running back you only have
            to start one at the position. You also have the option to start a
            running back at the flex position so that opens things up some.
            Remember Underdog is also a 0.5 PPR site so touchdowns are more
            important in this format. I also like to look at stacking my
            quarterback with his running back. I prefer this a lot more in
            Playoff Best Ball as you are almost betting on the entire team and
            not just a single player. I tend to lean with three running backs
            but also draft only two sometimes and am also not afraid to go with
            four.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            8. ADP and rankings don’t really matter
          </h3>
          <p className="mt-2">
            The goal is to try to stack offenses together, so ADP and rankings
            are not going to be your friend as much in this playoff format. I do
            not mind customizing my own rankings for the top 20 players but
            after that I like to do my rankings by teams/offenses. For example I
            will lump Prescott/Elliott/Cooper/Schultz/Pollard all next to each
            other so when I am drafting, I can make quicker decisions with my
            team stacks. You can also use the search function and just type in
            Cowboys, and it would do the same thing and just pull up all the
            players from that team. The only downfall I have with that is that
            you have a 30 second timer so if you are not quick enough you might
            miss your player.
          </p>
          <h3 className="mt-8 text-lg font-bold tracking-tight text-gray-900">
            9. Try to understand if the draft room is a sharp room or not
          </h3>
          <p className="mt-2">
            If you see the other drafters have badges, they are probably going
            to be your sharp drafters. If this is the case, you are going to be
            able to wait on stacks more as you often see these sharp drafters
            only drafting a single quarterback and stick closely to most of the
            lessons above. Sometimes you can wait on quarterback an extra couple
            of rounds because of what type of drafters you are drafting against,
            and this helps you to be able to take more risks throughout the
            draft.
          </p>
          <p className="mt-6">
            Thanks for reading to the end of this article! If you appreciate
            this content and want to see more of it every day, you can help us
            out by sharing this article on social media!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Strategy;
