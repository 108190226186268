import { createContext } from "react";
import { onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, onAuthStateChanged, db, doc } from "./services/firebase";

export const AuthContext = createContext();
export const ChatContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const isPurchased = userInfo?.connectedAccounts?.["james"]?.products?.length;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setHasLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (user && user.uid) {
      const userDocRef = doc(db, "users", user.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserInfo({ uid: user.uid, ...docSnapshot.data() });
        } else {
          console.error("No such document!");
        }
      });

      // Cleanup listener on component unmount
      return () => unsubscribe();
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user && user.uid ? true : false,
        hasLoaded,
        userInfo,
        isPurchased,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
