import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_LOGIN } from "..";
import { getCheckoutUrl } from "../../utils";
import Spinner from "../../Components/Spinner";
import SEO from "../../Components/SEO";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { rankingsList } from "../../rankings";
import UnderdogTable from "./tables/UnderdogTable";
import DraftersTable from "./tables/DraftersTable";
import DraftKingsTable from "./tables/DraftKingsTable";

function RankingView() {
  const { authenticated, isPurchased } = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const rankingId = params.rankingId;
  const [rankingConfig, setRankingConfig] = useState(null);

  useEffect(() => {
    // Find the ranking config using the rankingId
    const rankingData = rankingsList.find(
      (ranking) => ranking.id === rankingId
    );
    if (rankingData) {
      setRankingConfig(rankingData);
    }
  }, [rankingId]);

  const handleCheckout = async () => {
    setSubmitting(true);
    const url = await getCheckoutUrl();

    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div>
      <SEO
        title="Download Rankings"
        description="Download the best ball rankings that have won over $75K over just the last two seasons. Upload directly to Underdog."
        image="https://www.bestballrankings.com/static/media/Logo.97250659a2f6a1b83815.png"
      />
      <div className="pb-5 sm:flex sm:items-center sm:justify-between w-full">
        <h3 className="inline-flex items-center gap-x-2 text-lg font-bold leading-6 text-gray-900">
          <button onClick={() => navigate(-1)}>
            <ArrowLeftIcon className="h-5 w-5" />
          </button>
          {rankingConfig?.name}
        </h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          {authenticated && isPurchased ? (
            <a
              href={`${process.env.PUBLIC_URL}/assets/${rankingConfig?.asset}`}
              download={`jb-${rankingConfig?.id}.csv`}
              className="inline-flex items-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              Download CSV
            </a>
          ) : (
            <button
              type="button"
              onClick={() => {
                authenticated ? handleCheckout() : navigate(ROUTE_LOGIN);
              }}
              className="inline-flex items-center rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              {(!authenticated || !isPurchased) && !submitting ? (
                <LockClosedIcon className="h-3 w-3 mr-2" />
              ) : null}
              {submitting ? <Spinner color="#FFFFFF" /> : "Download"}
            </button>
          )}
        </div>
      </div>
      <div>
        {rankingConfig?.type === "underdog" && <UnderdogTable />}
        {rankingConfig?.type === "drafters" && <DraftersTable />}
        {rankingConfig?.type === "draftkings" && <DraftKingsTable />}
      </div>
    </div>
  );
}

export default RankingView;
